<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">

        <v-card elevation="3">
          <v-card-text flat elevation="3">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.equipmentCode'
                              label='Equipo'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.evaporatorCode'
                              label='Evaporador'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.trademarkName'
                              label='Marca'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.modelName'
                              label='Modelo'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.serieName'
                              label='Serie'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              </v-row>
              <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                    color="default"
                    depressed
                    outlined
                    class="mr-2"
                    @click="clearFilter()"
                  >
                    Limpiar
                  </v-btn>
              <v-btn
                    color="primary"
                    depressed
                    @click="fetchRecords(newFilter)"
                  >
                    Buscar
                  </v-btn>
            </v-row>                       
          </v-card-text>
          </v-card>
          <v-toolbar color="primary" flat>
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="fetchRecords(newFilter)">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
          <v-dialog v-model="dialog" max-width="800px">
            <evaporator-form v-bind.sync="editedItem"
                             :read-only="readOnly"
                             @cancel="close"
                             @save="handleFormSubmit"/>
          </v-dialog>
          <v-card-text>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="subtitle-1"
                >
                  ¿Estás seguro de que quieres eliminar el evaporador?
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="blue darken-1"
                         text
                         @click="dialogDelete = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItem"
                  >
                    OK
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="evaporators"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.equipmentCode`]="{ item }">
                <router-link :to="{name:'eq.edit', params:{id: item.equipmentCode}}">
                  {{ item.equipmentCode }}
                </router-link>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="green accent-5"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="viewItem(item)">
                      <v-icon color="white" small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Detalles</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="light-blue darken-3"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="editItem(item)">
                      <v-icon color="white" small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import EvaporatorForm from "@/components/forms/EvaporatorForm";
import EventBus from "@/util/eventbus";

export default {
  name: "EvaporatorList",
  components: { EvaporatorForm },
  data: () => ({
    options: {},
    dialog: false,
    dialogDelete: false,
    serverItemsLength: 0,
    loadingItems: false,
    editedItem: {},
    editedIndex: -1,
    readOnly: false,
    defaultItem: {
      code: null,
      trademark: null,
      model: null,
      serie: null,
      voltage: null,
      amperage: null,
      details: null
    },
    newFilter: {
      page: 1,
      PageSize: null,
      equipmentCode: null,
      evaporatorCode: null,
      trademarkName: null,
      modelName: null,
      serieName: null,
    },
    headers: [
      {
        text: "Equipo",
        value: "equipmentCode",
        width: 120,
      },
      {
        text: "Evaporador",
        value: "code",
        width: 120,
      },
      {
        text: "Marca",
        value: "trademark",
      },
      {
        text: "Modelo",
        value: "model",
      },
      {
        text: "Serie",
        value: "serie",
      },
      {
        text: "Voltaje",
        value: "voltage",
        width: 130,
      },
      {
        text: "Ampreaje",
        value: "amperage",
        width: 130,
      },
      {
        text: "Acciones",
        value: "actions",
        width: 40*2+32,
      },
    ]
  }),
  computed: {
    ...mapState('equipment', ['evaporators']),
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.newFilter.PageSize = itemsPerPage;
        this.newFilter.page = page;
        this.fetchRecords(this.newFilter);
      },
      deep: true,
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions('equipment', ['fetchEvaporators', 'editEvaporator', 'crateEvaporator', 'deleteEvaporator']),
    fetchRecords(query) {
      this.loadingItems = true;
      this.fetchEvaporators(query)
        .then((result) => this.serverItemsLength = result.totalItems)
        .finally(() => (this.loadingItems = false));
    },
    clearFilter() {
      this.loadingItems = true;
      this.newFilter.equipmentCode = null
      this.newFilter.evaporatorCode = null
      this.newFilter.trademarkName = null
      this.newFilter.modelName = null
      this.newFilter.serieName = null
      this.fetchRecords(this.newFilter)
      this.loadingItems = false;
    },
    _showDialog(item, readonly = false) {
      this.editedIndex = this.evaporators.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.readOnly = readonly
      this.dialog = true
    },
    showDeleteDialog(item) {
      this.editedIndex = this.evaporators.indexOf(item)
      this.dialogDelete = true
    },
    editItem(item) {
      this.$router.push({ name: 'eq.edit', params: { id: item.equipmentCode } })
    },
    viewItem(item) {
      this._showDialog(item, true)
    },
    deleteItem() {
      const message = 'El evaporador ha sido eliminado'
      this.deleteEvaporator(this.evaporators[this.editedIndex].code)
        .then(() => {
          this.serverItemsLength--
          EventBus.$emit("SHOW_SNACKBAR", {
            text: message,
            color: 'success',
          })
          this.dialogDelete = false
        })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.readOnly = false
      })
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    handleFormSubmit(item) {
      const message = 'Los datos han sido guardados exitosamente'
      if (item.code) {
        this.editEvaporator(item)
          .then(() => {
            this.close()
            EventBus.$emit("SHOW_SNACKBAR", {
              text: message,
              color: 'success',
            })
          })
      } else {
        this.crateEvaporator(item)
          .then(() => {
            this.close()
            this.serverItemsLength++
            EventBus.$emit("SHOW_SNACKBAR", {
              text: message,
              color: 'success',
            })
          })
      }
    }
  },
}
</script>

<style scoped>

</style>